/* TYPOGRAPHY */
$fontDirText: "../fonts/OpenSans";
$fontDirText2: "../fonts/SciFly";

@font-face {
    font-family: 'OpenSansCondensed-Light';
    src: url('#{$fontDirText}/OpenSansCondensed-Light.eot?#iefix') format('embedded-opentype'),  
        url('#{$fontDirText}/OpenSansCondensed-Light.woff') format('woff'), 
        url('#{$fontDirText}/OpenSansCondensed-Light.ttf')  format('truetype'), 
        url('#{$fontDirText}/OpenSansCondensed-Light.svg#OpenSansCondensed-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SciFly';
    src: url('#{$fontDirText2}/scifly-sans-webfont.woff') format('woff'), 
        url('#{$fontDirText2}/scifly-sans.ttf')  format('truetype');
    font-weight: normal;
    font-style: normal;
}

$fontStyledHeaderMain: 'SciFly';
$fontStyledHeaderPlain: 'OpenSansCondensed-Light';
$fontStyledText: 'OpenSansCondensed-Light';

$basefontStyled: 'OpenSansCondensed-Light';
$basefontStyledLight: 'OpenSansCondensed-Light';
$basefontQuotes: 'Times';

$fontSizeReduced: 0.85rem;

html {
    font-size: calc(17px + 0.3vw);

    // @media only screen and (min-width: (10 * 64px)) {
    //     //font-size: 2.5rem;
    // }
}

body {

}

h1 {
    font-family: $basefontStyled;
    color: $txt-color-brand;
    font-size: 2rem;
    line-height: 2.4rem;
}

h3 {
    font-family: $basefontStyledLight;
    font-size: 1.5rem;
    line-height: 1.75rem;
}

ul,
p {
    font-size: 1rem;
    font-family: $basefontStyledLight;
    line-height: 1.6rem;

    strong {
        @media only screen and (min-width: ($triggerGridChange)) {
            display: block;
            font-size: 1.2rem;
            margin-top: 1.5rem;
            margin-bottom: -0.25rem;
        }
    }
}

a,
.--link {
    color: $txt-color-link;
    cursor: pointer;
}
.--link-clean {
    cursor: pointer;
}

ul,
ol {
    margin: 0 0 0 0rem;
}

.page-content {
    &__title {
        font-family: $basefontStyled; 
        font-size: 2rem;       
    }

    &__subtitle {
        font-family: $basefontStyled;
        font-size: 0.9rem;
        text-transform: uppercase;
         
    }
}