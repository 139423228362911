/* BLOCKS */

.block {
    &--headerimg {
        &__header {
            display: block;
            font-family: $basefontQuotes;
            font-size: calc(1.25rem + 1.5vw);
            margin-top: -0.5rem;
        }
    
        &__subheader {
            display: block;
            margin-bottom: 1rem;
            font-family: $fontStyledHeaderPlain;
            font-size: calc(12px + 0.6vw);
            text-transform: uppercase;
        }
    }

    &--quote {
        position: relative;
        min-height: 50vh;
        display: flex;
        align-items: center;
        @media only screen and (min-width: (64px * 12)) {
            min-height: 40vw;
        }

        &__background {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            background-position: 75%;
            background-size: cover;
        }

        &__header {
            padding: 3rem $contentMarginSmall 5rem $contentMarginSmall;
            position: relative;
            font-family: $basefontQuotes;
            font-size: calc(1.25rem + 1.5vw);
            width: 65%;
            color: #FFF;  
            
            @media only screen and (min-width: (64px * 12)) {
                width: 40%;
                padding: 2rem $contentMargin 5rem $contentMargin;
            }
        }
    }

    &--section {
        position: relative;
        flex: 1;
        min-width: 20rem;
        color: #FFF;

        &:first-child {
            justify-content: right;

            .block--section__bg {
                position: absolute;
                background-color: #FFF;
                width: 5rem;
                height: 5rem;
                top: 0;
                right: 0;

                @media only screen and (min-width: ($triggerMobileNav)) {
                    height: 6rem;
                }
            }

            
        }

        &:last-child {
            justify-content: left;

            .block--section__bg {
                position: absolute;
                background-color: #FFF;
                width: 5rem;
                height: 5rem;
                top: 0;
                left: 0;

                @media only screen and (min-width: ($triggerMobileNav)) {
                    height: 6rem;
                }
            }           
        }

        &__content {
            position: relative;
            width: calc(100% - (2 + $contentMargin));
            //max-width: 20rem;
            padding: $contentMargin;
            border-radius: 2rem;
        }

        &__fade {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            border-radius: 0 0 2rem 2rem;

            &.bg-gradient-header {
                border-radius: 0;
            }
        }

        &__header {
            display: block;
            font-family: $fontStyledHeaderMain;
            font-size: calc(1.25rem + 1.5vw);
            margin-top: -0.5rem;
        }

        &__subheader {
            font-family: $fontStyledHeaderPlain;
            font-size: calc(12px + 0.6vw);
            text-transform: uppercase;
        }
    }
}

