/* LAYOUT */

html,
body {
    margin: 0;
    padding: 0;
}


.page-content {
    &--primary {
        padding: 0 0 2rem 0;
        background-color: rgba(#CCC, 0.4);
        //background-color: #CCC;
    }

    &--blog {
        &__image {
            height: 100px;
            width: 500px;

            img {
                width: 100%;
            }
        }
    }
}

.wrapper {
    &--header {
        z-index: 15;
    }
}

.wrapper {
    &--nav-modules {
        z-index: 10;
          margin-bottom: -2rem;
    }
}

.wrapper {
    &-content {
        &--section {
            &:nth-child(even) {
                background-color: rgba(#CCC, 0.2);
            }
        }
        &--text {
            position: relative;
            display: flex;
            flex-direction: column;
            width: calc(100% - (2 * $contentMarginSmall));
            max-width: $maxContentTextWidth;
            margin: 0 auto;
            padding: $contentMarginSmall;
            //background-color: #FFF;
            border-radius: 0 0 2rem 0;
            z-index: 25;

            @media only screen and (min-width: ($triggerGridChange)) {
                width: calc(100% - (2 * $contentMargin));
                padding: $contentMargin $contentMargin calc(#{$contentMargin} + 1rem) $contentMargin;
            }

            @at-root .page-content & {
                background-color: transparent;
            }
        }
    }

    &--page {
        display: flex;
        flex-direction: column;
        overflow-x: hidden;
    }

    &--section {
        position: relative;
        &__block-bg {
            &.--left {
                width: 100%;
                height: 100px;
                position: absolute;
                bottom: 0;
                left: -100%;
                background-color: #FFF;
                z-index: 25;
            }

            &.--right {
                width: 100%;
                height: 100px;
                position: absolute;
                bottom: 0;
                right: -100%;
                background-color: #FFF;
                border-radius: 0 0  0 2rem;
                z-index: 25;
            }
        }
    }
}

.page--content {
    margin: auto;
}

//SbC positioning nav-modules

.block--quote {
    + .wrapper--nav-modules {
        margin-top: -2rem;
    }

    + .form-row--buttons {
        transform: translateY(-5.5rem);
        margin-bottom: -5rem;
        @media only screen and (min-width: ($triggerGridChange)) {
            transform: translateY(-7rem);
        }
    }
}

.wrapper-content--section {
    + .wrapper--nav-modules {
        margin-top: -2rem;
        z-index: 100;
    }

    // .wrapper--nav-modules ~ & { 
    //     margin-top: -2rem;
    //     border: solid 1px green;
    // }
}

.wrapper--nav-modules {
    + .page-content--primary {
        padding-top: 2rem;
    }
}

.wrapper--nav-modules {
    + .block--reviews {
        padding-top: 3rem;
    }
}