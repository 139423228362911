.block--social {
    background-color: #CCC;
    color: #FFF;
    display: flex;
    justify-content: center;
    padding: 1rem;

    &__list {
        list-style-type: none;
        display: flex;
        padding: 0;
        margin: 0;
    }

    &__listitem {
        margin: 0.5rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #FFF;
        border-radius: 50%;
        padding: 0.25rem;

        img {
            width: 100%;
        }
    }
}