/* CONTENT */

.page-content {
    &__title {
        margin: 0;
        color: $txt-color-brand;
    }

    &__subtitle {
        margin: 0;

        + .page-content__title {
            line-height: 1;
        }
    }

    &__text {
        .content-list--steps {
            list-style-type: none;
            li {
                display: flex;
                align-items: flex-start;
                margin: 0.25rem 0;
            }
            .step {
                background-color: $bg-color-purple-tertiary;
                border-radius: 50%;
                height: 1rem;
                margin-right: 0.5rem;
                position: relative;
                width: 1rem;
                min-width: 1rem;
                color: #FFF;
                display: flex;
                align-items: center;
                justify-content: center;
                top: 0.35rem;
            }
        }
    }   
}

.logos--inline {
    ul {
        display: flex;
        flex-flow: row wrap;
        list-style: none;
        padding: 0;
        margin: 0 0 0 -0.5rem;
    }
    li {
        border: solid 1px #CCC;
        background-color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0.5rem;
    }
}
