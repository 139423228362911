/* BLOCK BLOGS */

.block--cooperations {
    position: relative;
    background-color: rgba(#CCC, 0.6);
    //margin-top: -2rem;
    padding: $contentMargin;
    
    &__title {
        font-family: $fontStyledHeaderMain;
        font-weight: 100;
        font-size: 2.5rem;
        margin-top: 0.15rem;
        letter-spacing: -2px;
    }

    &__subtitle {
        display: block;
        margin-top: 2rem;
        font-family: "OpenSansCondensed-Light";
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
    }

    .form-row--section {
        position: relative;
    }
}