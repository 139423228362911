.block--contact {
    padding: $contentMarginSmall;
    background-color: #000;
    color: #FFF;
    @media only screen and (min-width: ($triggerGridChange)) {
        padding: $contentMargin;
    }

    &__title {
        font-family: $fontStyledHeaderMain;
        font-weight: 100;
        font-size: 2.5rem;
        color: #FFF;
        margin-top: 0.15rem;
    }

    &__subtitle {
        display: block;
        margin-top: 2rem;
        font-family: "OpenSansCondensed-Light";
        font-size: 1rem;
        font-weight: bold;
        color: #FFF;
        text-transform: uppercase;
    }
}