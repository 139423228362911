// CAROUSELS

.swiper-pagination {
    display: none;
}

.carousel {
    width: 100%;
    margin: 0 auto 1rem;
    overflow: hidden; 

    @media only screen and (min-width: ($triggerGridChange)) {
        width: 75%;
    }

    @media only screen and (min-width: (64px * 12)) {
        width: 50%;
    }

    @media only screen and (min-width: (64px * 16)) {
        width: 40%;
    }
}

.carousel--banner__slide-content {
    overflow: hidden;
}

.swiper-button-prev,
.swiper-button-next {

}

.swiper-button-prev {

}

.swiper-button-next {

}