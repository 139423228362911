/* VARIABLES */
$max-width: 64rem;

$triggerGridChange: 8*64px;
$triggerMobileNav: 10*64px;

$maxContentWidth: 40rem;
$maxContentTextWidth: calc(40rem - 150px);

$contentMarginSmall: 1.5rem;
$contentMarginLeftSmall: 1.5rem;
$contentMarginRightSmall: 1.5rem;

$contentMargin: 2rem;
$contentMarginRight: 2rem;
$contentMarginLeft: 2rem;


/* text colors */
$txt-color: #000;
$txt-color-inverted: #FFF;
$txt-color-brand: #AA00BD;
$txt-color-yellow: #FFCA2C;
$txt-color-red: #D63A17;
$txt-color-link: #AA00BD;
$txt-color-green: #339966;

/* background colors */
// #9966CC

$bg-color: #000;

$bg-color-brand-purple: #D5DDD0;
$bg-color-brand-green: #339966;

//$bg-color-purple-secundary: #CC3366;
$bg-color-purple-secundary: #CC3366;
$bg-color-purple-tertiary: #AA00BD;

$bg-color-brand: #D5DDD0;
$bg-color-brand-light: #D5DDD0;
$bg-color-brand-dark: #EC7021;
$bg-color-focus: #CA2334;
$bg-color-inverted: #FFF;
$bg-color-header: #F98A14;
$bg-color-footer: #CCC;

$bg-color-red: #FE0000;
$bg-color-yellow: #FFCA2C;
$bg-color-forms: #E69C95;

//$bg-color-nav-block-primary: red;
//$bg-color-nav-block-secundary: green;

$bg-color-nav-block-priamry-incontent: #CC3366;
$bg-color-nav-block-secundary-incontent: #AA00BD;

/**/
$bg-color-nav-main: #D47FDE;
$bg-color-nav-main-active: #AA00BD;

/* border colors */

$border-color-gray: #CCC;
$shadow-color-dark: rgba($bg-color-brand-dark, 0.6);