/* FOOTER */

.wrapper--footer {
    background-color: #CCC;
}
.footer {
	width: 100%;
    //max-width: 40rem;
    //margin: 0 auto;
    padding: 2rem 0
}

.wrapper--navigation-footer {
    margin: 0 $contentMarginSmall;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: ($triggerGridChange)) {
        margin: calc(#{$contentMargin} - 1rem);
        width: calc(100% - (2 * #{$contentMargin}));
        flex-direction: row;
        justify-content: space-between;
    }

    // @media only screen and (min-width: (64px * 10)) {
    //     flex-direction: row;
    // }

    &__row {
        display: flex;
        flex-direction: row;
        margin-bottom: 3rem;
    }

    &__column {
        min-width: 33%;
        padding: 1rem;
    }

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        font-family: $basefontStyled;
        font-size: $fontSizeReduced;
        
        li {
            line-height: 1rem;
            padding: 0.25rem 0;
            &.--link {
                color: #000;
                font-family: $basefontStyled;
                font-size: $fontSizeReduced;

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
}

.footer {
    &__copyright {
        display: flex;
        //margin-top: 1rem;
        font-family: $basefontStyled;
        font-size: $fontSizeReduced;

        .copyright__list {
            list-style-type: none;
            margin: 0 $contentMarginSmall $contentMarginSmall;
            padding: 0;

            @media only screen and (min-width: ($triggerGridChange)) {
                //margin: $contentMargin;
                display: flex;
            }
            

            li {
                height: 1.5rem;
                margin: 0;
            
                @media only screen and (min-width: ($triggerGridChange)) {
                    margin: 0 0.5rem;
                }
                
                &:first-child {
                    font-weight: bold;
                }

                a {
                    text-decoration: none;
                    color: #000;
                }
            }
        }
    }
}