.wrapper {
    &--nav-modules {
        position: relative;
        display: flex;
        flex-direction: row;

        .block--section {
            width: 50%;
            min-width: 0;
            padding-top: 2rem;

            &:first-child {
                background-color: $bg-color-nav-main;
                border-radius: 0 2rem 2rem 0;

                &.--inContent {
                    //background-color: red;
                }

                .block--section__content {
                    background-color: $bg-color-nav-main;
                }

                &.--active {
                    background-color: $bg-color-nav-main-active;

                    .block--section__content {
                        background-color: $bg-color-nav-main-active;
                    }
                }
            }

            &:last-child {
                background-color: $bg-color-nav-main;
                border-radius: 2rem 0 0 2rem;

                &.--inContent {
                    //background-color:green;
                }

                .block--section__content {
                    background-color: $bg-color-nav-main;
                }

                &.--active {
                    background-color: $bg-color-nav-main-active;

                    .block--section__content {
                        background-color: $bg-color-nav-main-active;
                    }
                }
            }

            &.--inContent {
                padding-top: 0;
                padding-bottom: 1rem;
            }

            .wrapper-icon {
                position: relative;
                background-color: #FFF;
                border-radius: 50%;
                width: 1rem;
                height: 1rem;
                margin-left: 0.5rem;
    
                &__icon {
                    position: absolute;
                    display: block;
                    width: 30%;
                    height: 30%;
                    border-top: solid 3px $bg-color-purple-tertiary;
                    border-right: solid 3px $bg-color-purple-tertiary;
                    transform: rotate(45deg);
                    margin-top: 0.3rem;
                    margin-left: 0.2rem;
                    cursor: pointer;
                }
            }
        }

        .block--section__content {
            padding-top: calc(0.125rem + 2vw);
            padding-left: 1rem;

            @media only screen and (min-width: ($triggerMobileNav)) {
                padding-left: 2rem;
            }
        }

        .page-content__text {
            display: none;

            @media only screen and (min-width: ($triggerMobileNav)) {
                display: block;
            }
        }

        .block--section__fade .bg-gradient-coaches {
            display: none;
        }

        @media only screen and (min-width: (10 * 64px)) {
            flex-wrap: wrap;
        }

        .form-row--buttons {
            margin: 0.75rem;
            @media only screen and (min-width: (10 * 64px)) {
                margin: 1rem;
            }
        }
    }
}