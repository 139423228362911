/* HEADER */
//background-image: linear-gradient(rgba(0, 0, 0, 0.35), rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.35));

.wrapper--header {
    height: 7rem;
    &__block-bg {
        position: absolute;
        background-color: #FFF;
        top: 2rem;

        &.--left {
            background-color: #FFF;
            width: 100%;
            height: 100%;
            position: absolute;
            left: -100%;
        }

        &.--right {
            background-color: #FFF;
            width: 100%;
            height: 100%;
            position: absolute;
            right: -100%;
        }

        &--left {
            background-color: #FFF;
            border-radius: 0 0 2rem 0;
            width: 75px;
            height: 100%;
            left: 0;
        }

        &--right {
            background-color: #FFF;
            border-radius: 0 0 0 2rem;
            width: calc(100% - 75px);
            height: 100%;
            right: 0;
        }
    }
}

.header {
    position: relative;
    z-index: 10;
    display: flex;
    flex-flow: row wrap;
    height: 7rem;
    width: 100%;
    max-width: $maxContentWidth;
    margin: auto;
    justify-content: space-between;

    &__branding {
        padding: 11px;
        @media only screen and (min-width: ($triggerGridChange)) {
            padding: 0;
        }

        img {
            max-width: 125px;
            @media only screen and (min-width: ($triggerGridChange)) {
                max-width: 150px;
            }
        }
    }

    &__navigation {
        position: absolute;
        right: 0;
        
        ul {
            display: flex;
            flex-flow: column;
            list-style-type: none;
            margin: 0.4rem 1rem;
            padding: 0;

            @media only screen and (min-width: ($triggerMobileNav)) {
                flex-flow: row wrap;
                margin: 1rem $contentMarginRight;
            }

            li {
                margin: 0;
                text-align: right;

                @media only screen and (min-width: ($triggerMobileNav)) {
                    margin: 0 1rem;
                }

                a {
                    font-family: $fontStyledHeaderPlain;
                    text-decoration: none;
                }
            }
        }
    }

    &:last-child {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}

