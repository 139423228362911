/* FORMS */

.form__columns {
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: (64px * 12)) {
        flex-direction: row;
    }
}

.form__column {
    flex: 1;
    max-width: 500px;
}

.form-row {
    &--section {
        position: relative;
        margin: 1rem;
        
        &.--inline {
            position: absolute;
            right: calc(-1rem + 7vw);
            bottom: 0;
        }
    }

    &--input {
        margin-bottom: 1rem;
    }

    &--buttons {
        display: flex;
        justify-content: center;

        &.--contact {
            margin: 1rem;
        }
    }

    &--help {
        &.--incomplete {
            font-size: 1rem;
            font-style: italic;
            font-family: $fontStyledHeaderPlain;
            text-align: center;
        }
    }

    &--feedback {
        color: $txt-color-green;
        font-family: $fontStyledHeaderPlain;
    }
}

.btn {
    width: 4rem;
    height: 4rem;
    background-color: #FFF;
    border-radius: 50%;
    cursor: pointer;
    border: none;
    z-index: 30;

    &--primary {
        &.--inPhoto {
            font-size: 0.75rem;
        }
    }

    &--alternative {
        background-color: $bg-color-brand-green;
        color: #FFF;
        width: auto;
        height: auto;
        padding: 0.45rem;
        border-radius: calc(1.5rem + 1vw);
        font-size: 0.6rem;
        text-decoration: none;
        @media only screen and (min-width: ($triggerGridChange)) {
            font-size: 0.75rem;
            padding: 0.75rem;
            width: auto;
        }
    }

    &.--disabled {
        background-color: #444;
    }

    &--text {
        background-color: transparent;
        color: #FFF;
        width: auto;
        height: auto;
        font-size: 0.75rem;
    }
}

.form {
    &-field {
        &--error {
            display: block;
            color: #ff0000;
            font-size: 1rem;
            font-style: italic;
            font-family: $fontStyledHeaderPlain;
        }
    }
    &-column {
        &--feedback {
            font-size: 1rem;
            font-style: italic;
            font-family: $fontStyledHeaderPlain;
        }
    }
    &--inverted {
        label,
        .form-label {
            font-family: $basefontStyled;
            text-transform: uppercase;
            font-size: $fontSizeReduced;
        }
        input {
            width: 75%;
            max-width: 100%;
            height: 2rem;
            border: 0;
            border-bottom: solid 2px #CCC;
            background-color: transparent;
            color: #FFF;
            font-size: 1rem;
            font-style: italic;
            font-family: $fontStyledHeaderPlain;
        }
        textarea {
            width: 15rem;
            height: 10rem;
            border: 0;
            border-bottom: solid 1px #CCC;
            background-color: transparent;
            color: #FFF;
            font-size: 1rem;
            font-style: italic;
            font-family: $fontStyledHeaderPlain;
;        }
        textarea:focus, input:focus{
            outline: none;
        }
    }
}