/* GENERAL CONTENT */

.--link {
    cursor: pointer;
}

em {
    display: block;
    width: 70%;
    margin: 1.5rem 0 1.5rem 3rem;

    &:before {
        position: absolute;
        content: '"';
        font-family: $fontStyledHeaderMain;
        font-size: 3rem;
        left: 3rem;
    }
}

.link {
    &--read-more {
        font-family: $fontStyledHeaderPlain;
        font-size: $fontSizeReduced;
        color: $txt-color-link;
        display: flex;
        align-items: center;

        .wrapper-icon {
            position: relative;
            background-color: $txt-color-link;
            border-radius: 50%;
            width: 1rem;
            height: 1rem;
            margin-left: 0.5rem;

            &__icon {
                position: absolute;
                display: block;
                width: 30%;
                height: 30%;
                border-top: solid 3px #FFF;
                border-right: solid 3px #FFF;
                transform: rotate(45deg);
                margin-top: 0.3rem;
                margin-left: 0.2rem;
                cursor: pointer;
            }
        }
    }
}

.img--content {
    img {
        border-radius: 2rem;
    }

    &.--left {
        margin: 0 1.5rem 1rem0 0;
        text-align: center;

        @media only screen and (min-width: ($triggerMobileNav)) {
            float: left;
            text-align: left;
        }
    }

    &.--right {
        margin: 0 0 1rem 1.5rem;
        text-align: center;

        @media only screen and (min-width: ($triggerMobileNav)) {
            float: right;
            text-align: left;
        }
    }
}