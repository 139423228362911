/* SCROLLER */

.scroller-wrapper,
.scroller-back-wrapper {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $bg-color-purple-tertiary;
    cursor: pointer;

    &.--fadeOut{
        opacity:0;
        width:0;
        height:0;
        transition: width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s;
   }

   &.--fadeIn{
        opacity: 1;
        width: 2rem;
        height: 2rem;
        transition: width 0.5s, height 0.5s, opacity 0.5s 0.5s;
   }
}

.scroller-wrapper {
    &__icon {
        display: block;
        width: 30%;
        height: 30%;
        border-top: solid 3px #FFF;
        border-right: solid 3px #FFF;
        transform: rotate(-45deg);
        margin-top: 0.35rem;
        cursor: pointer;
    }
}

.scroller-back-wrapper {
    right: auto;
    bottom: auto;
    left: 1rem;
    @media only screen and (min-width: (64px*16)) {
        left: Min(calc(22% - 1rem), 14rem);
    }

    @at-root .app-content--mobile & {
        bottom: 1rem;
    }

    &__icon {
        display: block;
        width: 30%;
        height: 30%;
        border-top: solid 3px #FFF;
        border-left: solid 3px #FFF;
        transform: rotate(-45deg);
        margin-left: 0.35rem;
        cursor: pointer;
    }
}