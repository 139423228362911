// VIDEOS

.block--video {
    margin: auto;
    
    &.--landscape {
        width: 30rem;
    }

    &.--portrait {
        width: 15rem;
    }
}