/* GRADIENTS */

$gradients: () !default;
$gradients: map-merge((
    coaches: linear-gradient(0deg, $bg-color-nav-main 0%, rgba(0, 0, 0, 0) 60%, transparent),
    individuals: linear-gradient(0deg, $bg-color-nav-main 0%, rgba(0, 0, 0, 0) 60%, transparent),
    header: linear-gradient(90deg, #000 0%, rgba(0, 0, 0, 0.40) 0%, transparent),
), $gradients);

// Gradient backgrounds
@mixin bg-gradient($parent, $gradient) {
    #{$parent} {
      background: $gradient;
    }
}

// Gradient Classes 
@each $gradient, $value in $gradients {
    @include bg-gradient(".bg-gradient-#{$gradient}", $value);
}