/* COOPERATIONS */

.block--cooperations {
    z-index: 20;
    &__list {
        display: flex;
        flex-flow: row wrap;
        list-style-type: none;
        margin: 0 -1.5rem;
        padding: 0;
        @include foldOutListSummaries(1);
    }

    &__listitem {
        //min-width: 14rem;
        //max-width: calc(25% - 2rem);
        padding: 1rem;
        flex: 1;
    }
}

.list--cooperations {
    &__container-image {
        width: 150px;
        height: 150px;
        border: solid 1px #AAA;
        border-radius: 50%;
        margin: 0 auto 1rem auto;
        padding: 0.2rem;
    }

    &__container-content {
        padding: 0 0.5rem;
    }

    &__image {
        //background-color: rgba($bg-color-purple-secundary, 0.2);
        background-color: #FFF;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        img {
            width: 80%;
        }
    }

    &__title {
        display: block;
        font-family: $fontStyledText;
        font-weight: bold;
        margin-bottom: 0.75rem;
    }

    &__content {
        font-family: $fontStyledText;
        
        p {
            font-size: 0.9rem;
            line-height: 1.25rem;
        }
    }
}